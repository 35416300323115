import React from 'react';
import LandingPage from './LandingPage';
import './index.css';

const App = () => {
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default App;
